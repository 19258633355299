/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

body {
  font-family: 'Exo 2', sans-serif; }

header {
  position: relative;
  background: url(../img/header.png);
  background-size: cover;
  display: flex;
  align-items: center;
  padding-top: 75px; }
  header nav {
    position: absolute;
    width: 100%;
    top: 0;
    padding: 15px 0; }
    header nav ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      header nav ul li {
        margin: 20px;
        list-style: none; }
        header nav ul li a {
          color: #FFF;
          text-transform: uppercase; }
          header nav ul li a:hover {
            text-decoration: none;
            color: #FFF; }
  header .content {
    left: 0;
    right: 0;
    position: absolute; }
    header .content label {
      color: #FBC959;
      font-size: 24px;
      text-transform: uppercase;
      margin-top: 15px; }
    header .content h1 {
      color: #FFF;
      font-size: 2.3rem;
      text-transform: uppercase;
      line-height: 130%;
      letter-spacing: 1px; }
    header .content p {
      color: #FFF;
      font-size: 28px;
      text-transform: uppercase;
      line-height: 125%; }
  header .cta {
    position: absolute;
    background: #0A1E56;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 25px 0; }
    header .cta h5 {
      color: #FFF;
      font-size: 1.5rem; }
      header .cta h5 span {
        color: #FBC959; }
    header .cta button {
      background: #FBC959;
      color: #233170;
      border: 0;
      padding: 15px 40px;
      font-size: 20px;
      text-transform: uppercase;
      border-radius: 10px; }

section.conheca {
  background: #081024;
  padding: 105px 0; }
  section.conheca .separador {
    width: 120px;
    height: 2px;
    background: #FBC959;
    margin-bottom: 20px;
    margin-top: 15px; }
  section.conheca label {
    color: #FBC959;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 15px; }
  section.conheca h2 {
    color: #FFF;
    font-size: 2rem;
    text-transform: uppercase;
    line-height: 130%;
    letter-spacing: 1px;
    font-weight: 600; }
  section.conheca p {
    color: #FFF;
    font-size: 1.2rem; }

section.explore {
  background: #081024; }
  section.explore .border {
    background: #0A1E56;
    border-radius: 200px 0 200px 0;
    border: 0 !important;
    padding: 105px 0; }
    section.explore .border h3 {
      color: #FFF;
      font-size: 2rem;
      text-transform: uppercase;
      line-height: 130%;
      letter-spacing: 1px;
      font-weight: 600; }
    section.explore .border p {
      color: #FFF;
      font-size: 1.2rem; }
    section.explore .border article {
      border: 2px #FBC959 solid;
      border-radius: 60px 0 60px 0;
      padding: 25px;
      margin-bottom: 35px; }
      section.explore .border article h5 {
        color: #FBC959;
        font-size: 1.5rem;
        text-transform: uppercase;
        line-height: 130%;
        font-weight: 600; }
      section.explore .border article p {
        color: #FBC959; }

section.slide1 {
  background: #081024;
  padding: 105px 0; }
  section.slide1 .slick-slider .slick-slide {
    padding: 0 55px; }
  section.slide1 .item img {
    width: 100%;
    border: 5px #FFF solid; }
  section.slide1 .slick-dots li button {
    background: transparent;
    border-radius: 25px;
    border: 2px #FFF solid;
    width: 8px;
    height: 8px; }
    section.slide1 .slick-dots li button::before {
      display: none; }
  section.slide1 .slick-dots li.slick-active button {
    background: #FBC959;
    border: 2px #FBC959 solid; }
  section.slide1 .nav button {
    background: transparent;
    border: 0;
    margin: 0 25px; }

section.slide2 {
  background: #081024;
  padding: 105px 0; }
  section.slide2 .slick-slider .slick-slide {
    padding: 0 55px; }
  section.slide2 .item img {
    width: 100%;
    border: 5px #FFF solid; }
  section.slide2 .slick-dots li button {
    background: transparent;
    border-radius: 25px;
    border: 2px #FFF solid;
    width: 8px;
    height: 8px; }
    section.slide2 .slick-dots li button::before {
      display: none; }
  section.slide2 .slick-dots li.slick-active button {
    background: #FBC959;
    border: 2px #FBC959 solid; }
  section.slide2 .nav button {
    background: transparent;
    border: 0;
    margin: 0 25px; }
  section.slide2.plantas h5 {
    color: #FFF;
    font-size: 2rem;
    text-transform: uppercase;
    margin-bottom: 30px;
    font-weight: 600; }
  section.slide2.plantas .separador {
    width: 150px;
    height: 2px;
    background: #FBC959;
    margin-bottom: 20px;
    margin-top: 15px;
    margin: 0 auto; }
  section.slide2.plantas .item {
    text-align: center;
    margin-top: 25px; }
    section.slide2.plantas .item h5 {
      color: #FBC959; }
    section.slide2.plantas .item img {
      width: 70%;
      margin: 0 auto;
      border: 0; }

section.mapa {
  display: flex; }
  section.mapa .mapa {
    width: 100%; }
    section.mapa .mapa .image {
      width: 100%;
      height: 770px; }
      section.mapa .mapa .image iframe {
        object-fit: cover;
        width: 100%;
        height: 100%; }
  section.mapa .frase {
    background: #0A1E56;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 6rem;
    position: relative; }
    section.mapa .frase h5 {
      color: #FFF;
      font-size: 1.7rem;
      text-transform: uppercase;
      margin-bottom: 30px; }
    section.mapa .frase ul {
      margin-bottom: 0;
      padding-left: 30px;
      position: relative; }
      section.mapa .frase ul li {
        list-style: none;
        position: relative;
        margin-bottom: 5px;
        color: #FFF;
        font-size: 1.2rem; }
        section.mapa .frase ul li::before {
          content: '';
          background: url(../img/marker.svg);
          background-size: cover;
          width: 20px;
          height: 15px;
          position: absolute;
          left: -29px;
          top: 5px; }

section.slide3 {
  background: #081024; }
  section.slide3 .border {
    background: #0A1E56;
    border-radius: 200px 0 200px 0;
    border: 0 !important;
    padding: 105px 0; }
    section.slide3 .border h5 {
      color: #FFF;
      font-size: 2rem;
      text-transform: uppercase;
      line-height: 130%;
      letter-spacing: 1px;
      font-weight: 600; }
    section.slide3 .border p {
      color: #FFF;
      font-size: 1.4rem;
      text-transform: uppercase; }
    section.slide3 .border .nav button {
      background: transparent;
      border: 0; }
    section.slide3 .border .slick-slider .slick-slide {
      padding: 0 15px; }
    section.slide3 .border .slides-3 .item {
      border: 2px #FBC959 solid;
      border-radius: 60px 0 60px 0;
      padding: 30px 20px 30px 40px; }
      section.slide3 .border .slides-3 .item p {
        font-size: 1rem;
        color: #FBC959;
        margin-bottom: 0; }

section.form {
  background: #081024;
  padding: 115px 0 200px; }
  section.form .separador {
    width: 150px;
    height: 2px;
    background: #FBC959;
    margin-bottom: 20px;
    margin-top: 15px; }
  section.form h5 {
    color: #FFF;
    font-size: 2rem;
    text-transform: uppercase;
    line-height: 130%;
    letter-spacing: 1px;
    font-weight: 600; }
  section.form p {
    color: #FFF;
    font-size: 1.4rem;
    text-transform: uppercase; }
  section.form form label {
    color: #FFF;
    margin-bottom: 0;
    font-weight: 400; }
  section.form form input {
    border: 0;
    padding: 30px; }
    section.form form input.required {
      border: 1px #d41313 solid; }
  section.form form button {
    background: #FBC959;
    color: #233170;
    border: 0;
    padding: 15px 40px;
    font-size: 20px;
    text-transform: uppercase;
    border-radius: 10px;
    font-weight: 600;
    margin-top: 20px; }
    section.form form button[disabled="disabled"] {
      opacity: 0.5; }
  section.form form .message {
    margin-bottom: 15px; }
    section.form form .message.error {
      color: #d41313; }
    section.form form .message.success {
      color: #0e992c; }

footer {
  background: #0A1E56;
  padding: 30px 0 25px; }
  footer .footer {
    position: absolute;
    bottom: 0; }
  footer ul {
    margin: 85px 15px;
    padding: 0; }
    footer ul li {
      list-style: none;
      margin-bottom: 7px; }
      footer ul li a {
        color: #FFF;
        text-transform: uppercase;
        font-size: 15px; }
        footer ul li a:hover {
          text-decoration: none;
          color: #FFF; }
  footer .zoo {
    color: #FFF;
    margin-top: 55px; }
    footer .zoo img {
      width: 90px;
      margin-left: 10px; }

@media (max-width: 768px) {
  nav {
    display: none; }
  header {
    flex-direction: column;
    padding-top: 30px; }
    header .content {
      position: initial;
      text-align: center; }
    header .cta {
      position: initial;
      text-align: center; }
      header .cta button {
        margin-top: 15px; }
  section.conheca {
    padding: 75px 0; }
    section.conheca h2 {
      font-size: 1.7rem; }
    section.conheca p {
      margin-top: 20px; }
  section.explore .border {
    background: #0A1E56;
    border-radius: 50px 0 50px 0;
    border: 0 !important;
    padding: 75px 0; }
    section.explore .border h3 {
      font-size: 1.7rem; }
  section.slide1 .slick-dots li {
    width: 11px;
    height: 13px; }
    section.slide1 .slick-dots li button {
      padding: 3px; }
  section.mapa {
    flex-direction: column; }
    section.mapa .frase {
      width: 100%;
      padding: 35px 2rem; }
    section.mapa .mapa .image {
      width: 100%;
      height: 555px; }
  section.slide3 .border {
    background: #0A1E56;
    border-radius: 50px 0 50px 0;
    border: 0 !important;
    padding: 75px 0; }
    section.slide3 .border .nav {
      margin-bottom: 35px; }
  section.form {
    background: #081024;
    padding: 75px 0 70px; }
  section .slick-slider .slick-slide {
    padding: 0 10px !important; }
  footer .footer {
    position: initial; } }
